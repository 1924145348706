import { FileCopy, Folder, Person } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { AppButtonRoot } from 'app/components/AppBuutonRoot';
import Ficheiros from 'app/utils/Ficheiros';
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Cliente } from '../Clientes/util';
import Processo from '../processo/util';
import { Projecto } from '../projecto/util';
import { Usuario } from '../usuario/util';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {
  const lineChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Monthly Earnings',
        data: [10, 30, 50, 70, 20, 40],
        fill: true,
        backgroundColor: 'rgba(63, 81, 181, 0.2)',
        borderColor: '#3f51b5',
      },
    ],
  };

  const pieChartData = {
    labels: ['Pendente', 'Cancelado', 'andamento'],
    datasets: [
      {
        data: [1542, 6451, 84574],
        backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
      },
    ],
  };

  const [dashboardData, setDashboardData] = useState({
    totalProcesso: 0,
    totalCliente: 0,
    totalProjecto: 0,
    totalUsuario: 0,
    totalVisto: 0,
    totalDocumentos: 0,
    processosMensais: [],
    processosDiarios: [],
    processosHoje: [],
  });

  async function fetchData() {
    const cliente = new Cliente();
    const projecto = new Projecto();
    const usuario = new Usuario();
    const processo = new Processo();

    const ficheiros = new Ficheiros();

    try {
      const [
        totalCliente,
        totalProjecto,
        totalUsuario,
        totalVisto,
        totalProcesso,
        totalDocumentos,
        processosMensais,
        processosDiarios,
        processosHoje,
      ] = await Promise.all([
        cliente.contar(),
        projecto.contar(),
        usuario.contar(),
        visto.contar(),
        processo.contar(),
        ficheiros.contar(),
        processo.listarEstatisticaMensal(),
        processo.listarEstatisticaDiaria(),
        processo.listarEstatisticaHoje(),
      ]);

      setDashboardData((prev) => ({
        ...prev,
        totalCliente,
        totalProjecto,
        totalUsuario,
        totalVisto,
        totalProcesso,
        totalDocumentos,
        processosMensais,
        processosDiarios,
        processosHoje,
      }));
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }


  return (
    <AppButtonRoot>
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Grid container spacing={3}>
          {/* Top Metrics */}
          {[
            { title: 'Processos', value: '0', icon: <FileCopy />, color: '#42A5F5' },
            { title: 'Projectos', value: '0', icon: <Folder />, color: '#FF7043' },
            { title: 'Usuarios', value: '0', icon: <Person />, color: '#66BB6A' },
            { title: 'Clientes', value: dashboardData.totalCliente || 0, icon: <Person />, color: '#66BB6A' },

          ].map((metric, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                <Box sx={{ marginRight: 2, color: metric.color }}>{metric.icon}</Box>
                <CardContent>
                  <Typography variant="h6">{metric.title}</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metric.value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {/* Charts */}
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography variant="h6">Deste mês</Typography>
                <Line data={lineChartData} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">Processos</Typography>
                <Pie data={pieChartData} />
              </CardContent>
            </Card>
          </Grid>

          {/* Summary Section */}

        </Grid>
      </Box>
    </AppButtonRoot>
  );
};

export default Dashboard;
