import { Notify, NotifyError } from "app/utils/toastyNotification";
import axios from "axios";
import API_BASE_URL from "envirement";
import qs from 'query-string';

export const api = axios.create({
  baseURL: API_BASE_URL.PRODICTION
});

// Adiciona um interceptador de solicitação
api.interceptors.request.use(
  (config) => {

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {

      config.headers.Authorization = `Bearer ${user?.token}`;
    } else {

      // Redireciona para a rota de login se não houver token
    }
    return config;
  },
  (error) => {

    return Promise.reject(error);
  }
);

export const useApi = () => ({
  logar: async (email, senha) => {
    try {
      const resp = await api.post("/sessao", { email, senha });
      if (resp?.status == 400) {
        NotifyError(resp?.data?.message);
      }
      if (resp?.status == 200) {
        const { user } = resp?.data;

        localStorage.setItem("user", JSON.stringify(user));

        return resp;
      }


    } catch (error) {
      return error?.response;
    }
  },
  listQuery: async (path) => {
    try {
      const response = await api
        .get(`/${path}`)
        .then((response) => response || response)
        .catch(({ response }) => response);

      if (response.status === 404 || response.status === 403 || response.status === 400 || response.status === 401 || response.status === 402) {
        console.log(response?.message);
      }
      return response;
    } catch (error) {
      console.log(response?.message);
    }
  },
  getPerfil: async (path) => {
    try {
      const response = await api
        .get(`/${path}`)
        .then((response) => response || response)
        .catch(({ response }) => response);

      if (response.status === 404 || response.status === 403 || response.status === 400 || response.status === 401 || response.status === 402) {

      }
      return response;
    } catch (error) {
      console.log("erro inesperado:", error);
    }
  },
  sair: async () => {
    try {
      const response = await api.post("/sair");
      return response.data;
    } catch (error) {
      NotifyError("Erro ao sair");
      throw error;
    }
  },

  add: async (path, data) => {
    try {
      const response = await api
        .post(`/${path}`, data)
        .then((response) => response || response)
        .catch(({ response }) => response);
      console.log("RESPONSE", response);

      return response;
    } catch (error) {
      NotifyError("erro inesperado:", error);
    }
  },
  documento: async ({ path, title = "MAPA DE CONTROLO", queries = {} }) => {
    try {
      // Filtra queries válidas (não undefined ou null)
      const validQueries = Object.fromEntries(
        Object.entries(queries).filter(([_, value]) => value !== undefined && value !== null)
      );

      // Constrói a URL com queries
      const queryString = qs.stringify(validQueries);
      const finalPath = queryString ? `${path}?${queryString}` : path;

      // Faz a requisição com a URL completa
      const response = await api.get(finalPath, {
        responseType: "blob" // Indica que esperamos uma resposta de arquivo binário (blob)
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "blank");
      document.body.appendChild(link);
      link.click();
      link.title = title;
      link.remove();
    } catch (error) {
      NotifyError("Erro inesperado:", error);
    }
  },
  edit: async (path, data) => {
    try {
      const response = await api.put(`/${path}/`, data);

      if (response.status !== 200 && response.status !== 201) {
        NotifyError(response?.data?.message);
        return {};
      }

      Notify(response.data.message);
      return response;
    } catch (error) {
      if (error.response) {
        NotifyError(error.response?.data?.message || "Erro inesperado ao fazer a solicitação.");
        return error.response;
      } else {
        NotifyError("Erro inesperado:", error);
      }
    }
  }
  ,
  editOne: async (path, data) => {
    try {
      const response = await api.patch(`/${path}/`, data).then((response) => response || response)
        .catch(({ response }) => response);
      console.log("RESPONSE", response);

      return response;

    } catch (error) {
      console.error(`Error editing ${path}:`, error.response);
      throw error; // rethrow the error to allow for proper error handling
    }
  }
  ,
  editQuery: async (path, data) => {
    try {
      const response = await api.put(`/${path}`, data);

      if ([400, 401, 402, 403].includes(response.status)) {
        NotifyError(response?.message);
        return response;
      }

      return response;
    } catch (error) {
      const response = error.response;
      if ([400, 401, 402, 403].includes(response?.status)) {
        NotifyError(response?.message);
      } else {
        NotifyError("Erro inesperado:", error);
      }
      return response;
    }
  }
  ,
  delete: async (path, id) => {
    console.log("outro", id);
    try {
      const response = await api.delete(`/${path}/${id}`);

      if ([400, 401, 402, 403].includes(response.status)) {
        NotifyError(response?.message);
        return;
      }

      return response;
    } catch (error) {
      const response = error.response;
      if ([400, 401, 402, 403].includes(response?.status)) {
        NotifyError(response?.message);
      } else {
        NotifyError("Erro inesperado:", error);
      }
      return response;
    }
  }
  ,
  // Outros métodos aqui...
  list: async (path, page = 1) => {
    try {
      const response = await api.get(`/${path}/?page=${page}`);

      if ([400, 401, 402, 403].includes(response.status)) {
        console.log(response?.message);

        return;
      }

      return response;
    } catch (error) {
      const response = error.response;
      if ([400, 401, 402, 403].includes(response?.status)) {
        console.log(response?.message);
      } else {
        console.log(response?.message);
      }
      return response;
    }
  },

  add: async (path, data) => {
    try {
      const response = await api.post(`/${path}`, data);
      console.log("CADASTRAMENTO ", response);

      if ([400, 401, 402, 403].includes(response.status)) {
        NotifyError(response?.message);
        return response;
      }

      Notify(response?.data?.message);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "Erro inesperado";
      console.log(error)
      console.log("%cERRO", "color: red; font-size:xx-large", errorMessage);
      NotifyError(errorMessage);
      return error?.response?.data;
    }
  }

  // Outros métodos aqui...
});
