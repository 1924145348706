import { CBadge } from "@coreui/react";
import { Add, Edit, MoreVert, PermDeviceInformationOutlined, Search, Visibility, WorkOutline } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    styled,
    useTheme,
} from "@mui/material";
import { H2 } from "app/components/Typography";
import { useApi } from "app/hooks/useApi";
import useAuth from "app/hooks/useAuth";
import { generateBreadcrumbs } from "app/utils/generateBreadcrumbs";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Processo from "../processo/util";
import { Projecto } from "./util";

const AppButtonRoot = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

export default function Listar() {
    const { palette } = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [filtroStatus, setFiltroStatus] = useState("");
    const [ordem, setOrdem] = useState(""); // "asc" ou "desc"
    const [projectos, setProjectos] = useState([]);
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const { user } = useAuth()

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    // 5. Funções para UI
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleMenuOpen = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const handleView = ({ id }) => {
        console.log("ROW", selectedRow?.id);

        // redirecionar para a pagina de detalhe do processo
        navigate(`/projectos/${selectedRow?.id}/detail`,)
    };
    const handleEdit = () => {

        navigate(`/projectos/${selectedRow?.id}/edit`, { replace: true })
    }

    const handlePdf = async () => {
        const processo = new Processo();
        await processo.gerarFicha({ processoId: selectedRow?.id });
    };

    const handleGenerateReport = (e) => {
        e.preventDefault();
        console.log("Gerando relatório...");
        handleCloseModal();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFiltroStatus = (e) => {
        setFiltroStatus(e.target.value);
    };

    const handleOrdemChange = (e) => {
        setOrdem(e.target.value);
    };
    // Filtrando os dddprojecto
    const projectoClass = new Projecto();

    useEffect(() => {
        // Função para buscar dddprojecto
        const fetchdddprojecto = async () => {
            try {
                const alldddprojecto = await projectoClass.buscar() || [];

                // Filtrando dddprojecto com base no tipo de usuário
                let filteredprojecto;
                if (user?.painel?.nome.toLowerCase() === 'administrador' || user?.painel?.nome?.toLowerCase() === 'administrador geral' || user?.painel?.nome?.toLowerCase() === 'administrador de projecto') {
                    filteredprojecto = alldddprojecto; // Administrador: todas as dddprojecto
                } else if (user?.painel?.nome?.toLowerCase() === 'gestor de projecto') {
                    filteredprojecto = alldddprojecto.filter(project =>
                        project?.gestores?.some(gest => gest?.id === user?.id)); // Filter tasks where the user is a project manager
                    console.log("MINHAS TASK", filteredprojecto); // Gestor: dddprojecto do projeto específico
                }
                setProjectos(filteredprojecto);
            } catch (error) {
                console.error("Erro ao buscar dddprojecto:", error);
            }
        };

        fetchdddprojecto();
    }, [user]);
    const filteredProjectos = projectos
        .filter((proj) => {
            const filtroStatusValido = filtroStatus === "" || proj.statusId === filtroStatus;
            const pesquisaValida =
                searchTerm === "" ||
                proj.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
                new Date(proj.createdAt).getFullYear() == searchTerm ||
                proj.gestores?.filter(gest => gest?.nome?.toLowerCase().includes(searchTerm.toLowerCase()));
            return filtroStatusValido && pesquisaValida;
        })
        .sort((a, b) => {
            const nomeA = a.nome.toLowerCase();
            const nomeB = b.nome.toLowerCase();

            if (ordem === "asc") {
                return nomeA < nomeB ? -1 : nomeA > nomeB ? 1 : 0;
            }

            if (ordem === "desc") {
                return nomeA > nomeB ? -1 : nomeA < nomeB ? 1 : 0;
            }

            return 0; // Caso nenhuma ordem seja definida
        });


    const location = useLocation();
    const routeSegments = generateBreadcrumbs(location);

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                {/* Breadcrumbs aqui */}
            </Box>
            <H2>Lista de Projetos ({projectos.length})</H2>

            <Box p={2} sx={{ backgroundColor: "#f5f5f5", borderRadius: "8px", mb: 3 }} display="flex" justifyContent="space-between" mb={2} gap={2}>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Pesquisar projeto, gestor..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: <Search />,
                    }}
                />
                <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Status</InputLabel>
                    <Select value={filtroStatus} onChange={handleFiltroStatus} label="Status">
                        <MenuItem value="">
                            <em>Todos</em>
                        </MenuItem>
                        <MenuItem value={1}>Ativo</MenuItem>
                        <MenuItem value={2}>Inativo</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <InputLabel>Ordenar por</InputLabel>
                    <Select value={ordem} onChange={handleOrdemChange} label="Ordenar por">
                        <MenuItem value="">
                            <em>Sem Ordem</em>
                        </MenuItem>
                        <MenuItem value="asc">Alfabética Crescente</MenuItem>
                        <MenuItem value="desc">Alfabética Decrescente</MenuItem>
                    </Select>
                </FormControl>
                <Link to={`/projectos/add`}>
                    <Button variant="contained" color="primary" startIcon={<Add />}>
                        Criar Novo
                    </Button>
                </Link>
            </Box>
            <Card elevation={3}>
                <Box p={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Projeto</TableCell>
                                <TableCell>Ano</TableCell>
                              
                                <TableCell align="center">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredProjectos
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((proj, index) => (
                                        <TableRow key={index}>
                                            <TableCell><CBadge ><WorkOutline className={`${index % 2 == 0 ? " text-black" : "text-warning"}`}></WorkOutline></CBadge> {proj?.nome}</TableCell>
                                            <TableCell><CBadge className={`${index % 2 == 0 ? "bg-secondary" : "bg-warning"}`}>{new Date(proj?.createdAt).getFullYear()}</CBadge></TableCell>
                                            <TableCell align="center">
                                                {/* Botão de menu */}
                                                <IconButton
                                                    aria-controls="actions-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleMenuOpen(e, proj)}
                                                >
                                                    <MoreVert />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                        {/* Menu Dropdown */}
                        <Menu
                            id="actions-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleView}>
                                <ListItemIcon>
                                    <Visibility color="primary" />
                                </ListItemIcon>
                                <ListItemText>Ver</ListItemText>
                            </MenuItem>
                            {(user?.painel?.nome?.toUpperCase()?.includes("ADMINISTRADOR")) &&
                                <MenuItem onClick={handleEdit}>
                                    <ListItemIcon>
                                        <Edit color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>Editar</ListItemText>
                                </MenuItem>}
                            <MenuItem onClick={handlePdf}>
                                <ListItemIcon>
                                    <PermDeviceInformationOutlined color="error" />
                                </ListItemIcon>
                                <ListItemText>PDF</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredProjectos.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Card>
        </AppButtonRoot>
    );
}
