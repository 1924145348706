import { Alert, Box, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

const InternetStatusNotifier = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowSnackbar(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowSnackbar(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={isOnline ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {isOnline ? 'Você está conectado à internet.' : 'Você está offline. Verifique sua conexão.'}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InternetStatusNotifier;
