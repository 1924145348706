import React from "react";
import {
  CButton,
  CCol,
  CFormInput,

  CRow
} from "@coreui/react";
import { Avatar, Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SimpleCard } from "app/components";
import { Edit, EditNote } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { StyledButton } from "app/views/material-kit/buttons/AppButton";


export default function FormularioVisualizarDadosPessoais({ cliente }) {
  const { id } = useParams()

  return (
    <>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            DADOS DE IDENTIFICAÇÃO DO REPRESENTANTE DA EMPRESA
          </Typography>
          {/* <Box mb={2} display="flex" justifyContent="flex-end">
            <Link to={`/clientes/${cliente?.id}/editar`}>
              <Button variant="contained" color="success" startIcon={<EditNote />}>
                Editar
              </Button>
            </Link>
          </Box> */}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome Completo (Representante)"
                value={cliente?.nome || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Telefone 1"
                value={cliente?.telefone1 || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Telefone 2"
                value={cliente?.telefone2 || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                value={cliente?.email || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <Box pt={4}>
            <Typography variant="h6">Dados Da Empresa</Typography>
            <hr />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome da Empresa"
                value={cliente?.nomeEmpresa || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="NIF"
                value={cliente?.nif || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Web site"
                value={cliente?.site || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Área de Actuação"
                value={cliente?.ramo || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Município"
                value={cliente?.municipio || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Comuna"
                value={cliente?.comuna || ''}
                disabled
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid >
          <Box className="m-4">
            <Typography variant="body1">
              Registado por: <strong>{cliente?.atendente?.nome || "Anônimo"}</strong>
            </Typography>
            <Typography variant="body1">
              Perfil: <strong>{cliente?.atendente?.painel?.nome || "Secretaria"}</strong>
            </Typography>
            <Typography variant="body1">
              em: <strong>{new Date(cliente?.createdAt).toLocaleDateString("pt-PT", { hour: "2-digit", minute: "2-digit", second: "2-digit" })}</strong>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>

  );
}
