import { authRoles } from "app/auth/authRoles";
import PrivateRoutes from "app/auth/privateRoutes";
import Loadable from "app/components/Loadable";
import { lazy } from "react";


const Add = Loadable(lazy(() => import("./Add")));
const AddParaCliente = Loadable(lazy(() => import("./AddPersonalizado")));
const Edit = Loadable(lazy(() => import("./Edit")));
const List = Loadable(lazy(() => import("./Listar")));
const Detail = Loadable(lazy(() => import("./Detalhar")));
const Relatorio = Loadable(lazy(() => import("./Relatorio")));


const projectoRoutes = [
    
    
    { path: "/projectos/add", element: <Add /> },
    { path: "/projectos/relatorio", element: <Relatorio /> },


    {
        path: "/projectos/list", element: <PrivateRoutes auth={authRoles.gestorCliente.concat(authRoles.gestorProjecto)}>
            <List />
        </PrivateRoutes>
    },
    

    {
        path: "/projectos/:id/edit", element: <PrivateRoutes auth={authRoles.tecnicoProcesso}>
            <Edit />
        </PrivateRoutes>
    },
    {
        path: "/projectos/:id/detail", element: <PrivateRoutes auth={authRoles.tecnicoProcesso}>
            <Detail />
        </PrivateRoutes>
    },

];

export default projectoRoutes;