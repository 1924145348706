import { CBadge } from "@coreui/react";
import { Delete, Edit, MoreVert, Visibility } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    FormControl,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { SimpleCard } from "app/components";
import { Paragraph } from "app/components/Typography";
import { useApi } from "app/hooks/useApi";
import { generateBreadcrumbs } from "app/utils/generateBreadcrumbs";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Gestores } from "./util";

const AppButtonRoot = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
    "& .button": { margin: theme.spacing(1) },
}));

const ProductTable = styled(Table)(() => ({
    minWidth: 400,
    "& td": { borderBottom: "none" },
}));

export default function ListarComFiltros() {
    const api = useApi();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const redirect = useNavigate()
    const [filters, setFilters] = useState({
        clienteId: "",
        projetoId: "",
        search: "",
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [clientes, setClientes] = useState([]);
    const [projetos, setProjetos] = useState([]);

    // Handlers de Filtros
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prev) => ({ ...prev, [name]: value }));

        // Atualizar projetos ao mudar cliente
        if (name === "clienteId") {
            if (value) {
                api.listQuery(`projectos?clienteId=${value}`).then(({ data }) => {
                    setProjetos(data?.projectos || []);
                });
            } else {
                setProjetos([]);
            }
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        setFilters((prev) => ({ ...prev, search: value }));
    };
    // Funções de Ações
    const handleView = (e) => {
        redirect(`/gestores/${selectedRow?.id}`)
    };

    const handleEdit = (id) => {
        console.log("Editar item", id);
    };
    const handleMenuOpen = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Tem certeza de que deseja eliminar este item?")) {
            try {
                await api.delete(`gestores/${id}`);
                setData((prev) => prev.filter((item) => item.id !== id));
                setFilteredData((prev) => prev.filter((item) => item.id !== id));
                console.log("Item eliminado com sucesso:", id);
            } catch (error) {
                console.error("Erro ao eliminar item:", error);
            }
        }
    };
    // Paginação
    const handleChangePage = (_, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Fetch Inicial
    const gestoresClass = new Gestores()
    useEffect(() => {
        setLoading(true);
        api.list("gestores").then(({ data }) => {
            console.log(data);
            const dataGestores = data?.data?.gestores?.filter((item) => item?.painel?.nome?.includes("GESTOR DE PROJECTO"))
            setData(dataGestores || []);
            setFilteredData(dataGestores || []);
            setLoading(false);
        });

        api.list("clientes").then(({ data }) => setClientes(data?.clientes || []));
    }, []);

    // Aplicar Filtros
    useEffect(() => {
        const filtered = data.filter((item) => {
            return (
                (!filters.clienteId || item.clienteId === filters.clienteId) &&
                (!filters.projetoId || item.projetoId === filters.projetoId) &&
                (!filters.painelId || item.painelId === filters.painelId) &&
                (!filters.search ||
                    item.nome?.toLowerCase()?.includes(filters.search?.toLowerCase()) ||
                    item.email?.toLowerCase()?.includes(filters.search?.toLowerCase()))
            );
        });
        setFilteredData(filtered);
    }, [filters, data]);
    const location = useLocation();
    const routeSegments = generateBreadcrumbs(location);
    return (
        <AppButtonRoot>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {routeSegments.map((segment, index) => (
                    <Link key={index} to={segment.link} style={{ textDecoration: "none" }}>
                        <Typography variant="body2" color="textPrimary">
                            {segment.name}
                        </Typography>
                    </Link>
                ))}
            </Breadcrumbs>
            <Box display="flex" justifyContent="space-between" mb={3}>
                <h2>Gestores de Projecto</h2>
                <Link to="/gestores/add">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                    >
                        Criar Novo
                    </Button>
                </Link>
            </Box>

            {/* Filtros */}
            <SimpleCard>
                <Card elevation={3} sx={{ p: 2, mb: 3 }}>
                    <Box display="flex" gap={2} alignItems="center">
                        {/* Filtro de Clientes */}
                        <FormControl fullWidth>
                            <InputLabel>Clientes</InputLabel>
                            <Select
                                size="small"
                                name="clienteId"
                                value={filters.clienteId}
                                onChange={handleFilterChange}
                            >
                                <MenuItem value="">Todos</MenuItem>
                                {clientes.map((cliente) => (
                                    <MenuItem key={cliente.id} value={cliente.id}>
                                        {cliente.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Filtro de Projetos */}
                        <FormControl fullWidth>
                            <InputLabel>Projetos</InputLabel>
                            <Select
                                size="small"
                                name="projetoId"
                                value={filters.projetoId}
                                onChange={handleFilterChange}
                                disabled={!filters.clienteId} // Desabilita sem cliente selecionado
                            >
                                <MenuItem value="">Todos</MenuItem>
                                {projetos.map((projeto) => (
                                    <MenuItem key={projeto.id} value={projeto.id}>
                                        {projeto.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Perfil</InputLabel>
                            <Select
                                size="small"
                                name="painelId"
                                value={filters.painelId}
                                onChange={handleFilterChange}
                            // Desabilita sem cliente selecionado
                            >
                                <MenuItem value="">Todos</MenuItem>
                                {[{ nome: "GESTOR DE PROJECTO", id: 6 }, { nome: "GESTOR DE PROJECTO CLIENTE", id: 5 }].map((panel) => (
                                    <MenuItem key={panel.id} value={panel.id}>
                                        {panel.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* Filtro por Nome/Email */}
                        <TextField
                            size="small"
                            placeholder="Buscar por Nome ou Email"
                            name="search"
                            value={filters.search}
                            onChange={handleSearch}
                            fullWidth
                        />
                    </Box>
                </Card>

                {/* Tabela */}
                <Box>
                    <ProductTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{item.nome}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.cliente?.nome}</TableCell>
                                        <TableCell sx={{ px: 0 }}>
                                            <Paragraph  >
                                                <CBadge className={item?.painelId === 5 ? "bg-info" : `bg-warning text-black`}> {item?.painel?.nome} </CBadge>
                                            </Paragraph>
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* Botão de menu */}
                                            <IconButton
                                                aria-controls="actions-menu"
                                                aria-haspopup="true"
                                                onClick={(e) => handleMenuOpen(e, item)}
                                            >
                                                <MoreVert />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                        <Menu
                            id="actions-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleView} >
                                <ListItemIcon>
                                    <Visibility color="primary" />
                                </ListItemIcon>
                                <ListItemText>Ver</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>
                                <ListItemIcon>
                                    <Delete color="error" />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleEdit}>
                                <ListItemIcon>
                                    <Edit color="secondary" />
                                </ListItemIcon>
                                <ListItemText>Editar</ListItemText>
                            </MenuItem>
                        </Menu>
                    </ProductTable>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </SimpleCard>
        </AppButtonRoot>
    );
}
