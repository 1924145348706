import { authRoles } from "app/auth/authRoles";
import PrivateRoutes from "app/auth/privateRoutes";
import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Add = Loadable(lazy(() => import("./Add")));
const Detalhar = Loadable(lazy(() => import("./Detalhar")));
const List = Loadable(lazy(() => import("./Listar")));

const processoRoutes = [

    { path: "/processos/add", element: <Add /> },
    {
        path: "/processos/list", element: <PrivateRoutes auth={authRoles.tecnicoProcesso}>
            <List />
        </PrivateRoutes>
    }, 
    {
        path: "/processos/:processoId/detail", element: <PrivateRoutes auth={authRoles.tecnicoProcesso.concat(authRoles.gestorCliente)}>
            <Detalhar />
        </PrivateRoutes>
    }, 
];

export default processoRoutes;