import "@coreui/coreui/dist/css/coreui.min.css";
import CssBaseline from "@mui/material/CssBaseline";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../fake-db";
import { MatxTheme } from "./components";
import InternetStatusNotifier from "./components/InternetStatusNotifier";
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
import routes from "./routes";
import { useEffect } from "react";

export default function App() {
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const location = useLocation();

  // Função para redirecionar para o login
  // const redirectToLogin = async () => {
  //   const isLoginPage = location.pathname === "/session/signin";
  //   console.log("PATH", location.pathname);

  //   // Se não estiver na página de login, redirecione e limpe a sessão
  //   if (!isLoginPage) {
  //     localStorage.clear(); // Limpa o armazenamento local
  //     navigate('/session/signin', { replace: true }); // Navega para a página de login
  //   }
  // };

  // useEffect(() => {
  //   // Não faz nada se a página atual for a de login
  //   if (location.pathname === "/session/signin") {
  //     return;
  //   }

  // let timeoutActivity; // Timeout para inatividade
  // let timeoutLoading; // Timeout para carregamento demorado
  // const inactivityLimit = 240000 ^ 3; // 4 minutos em milissegundos
  // const loadTimeLimit = 20000; // 20 segundos em milissegundos

  // const handleActivity = () => {
  //   clearTimeout(timeoutActivity);
  //   timeoutActivity = setTimeout(redirectToLogin, inactivityLimit);
  // };

  // // Adiciona ouvintes de evento para a atividade do usuário
  // window.addEventListener("mousemove", handleActivity);
  // window.addEventListener("keypress", handleActivity);

  // Definindo o timeout para verificar velocidade de carregamento
  // timeoutLoading = setTimeout(redirectToLogin, loadTimeLimit); // 30 segundos

  // Limpeza dos ouvintes e timeouts ao desmontar
  //   return () => {
  //     clearTimeout(timeoutActivity);
  //     clearTimeout(timeoutLoading);
  //     // window.removeEventListener("mousemove", handleActivity);
  //     // window.removeEventListener("keypress", handleActivity);
  //   };
  // }, [navigate, location]); // Adiciona location como dependência

  // useEffect(() => {
  //   const isLoginPage = location.pathname === "/session/signin";
  //   if (!isLoginPage && location.pathname !== "/session/signin") {
  //     navigate("/session/signin", { replace: true }); // Força o redirecionamento
  //   }
  // }, [location, navigate]);
  return (
    <SettingsProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <InternetStatusNotifier></InternetStatusNotifier>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          {content}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
}
