import { authRoles } from "app/auth/authRoles";
import PrivateRoutes from "app/auth/privateRoutes";
import Loadable from "app/components/Loadable";
import { lazy } from "react";


const Add = Loadable(lazy(() => import("./Add")));
const Edit = Loadable(lazy(() => import("./Edit")));

const Detalhar = Loadable(lazy(() => import("./Detalhar")));
const List = Loadable(lazy(() => import("./Listar")));

const tipoDeVistoRoutes = [
    { path: "/tipo-de-visto/add", element: <Add /> },
    
    {
        path: "/tipo-de-visto/list", element: <PrivateRoutes auth={authRoles.gestorVistoSecretaria}>
            <List />
        </PrivateRoutes>
    },
    {
        path: "/tipo-de-visto/:id/edit", element: <PrivateRoutes auth={authRoles.gestorVistoSecretaria}>
            <Edit />
        </PrivateRoutes>
    },
    {
        path: "/tipo-de-visto/:id/detail", element: <PrivateRoutes auth={authRoles.gestorVistoSecretaria}>
            <Detalhar />
        </PrivateRoutes>
    },
];

export default tipoDeVistoRoutes;